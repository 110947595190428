export const environment = {
  production: true,
  hmr: false,
  showConsoleLog: false,
  apiUrl: 'https://ecmsapi.judiciary.dl.gov.ng/api',
  courtTitle: 'HIGH COURT OF JUSTICE',
  courtTitleSentence: 'High Court of Justice',
  stateTitle: 'DELTA STATE',
  stateTitleSentence: 'Delta State',
  deptTitle: 'PROBATE DEPARTMENT',
  BASE_URL: 'https://ecmsapi.judiciary.dl.gov.ng/api',
  BASE_URL_II: 'https://ecmsapi.judiciary.dl.gov.ng/api',
  API_URL: '/',
  PORTAL_URL: 'https://portal.judiciary.dl.gov.ng',
  currentState: 'Delta State',
  currentDivision: '',
  court_bg_path: 'assets/img/court-bg-5.png',
  court_logo_path: 'assets/img/ecms-logos-delta.png',
  court_logo_1: 'assets/img/coat-of-arms-tp.png',
  court_logo_2: 'assets/images/ecms-logos-delta.png',
  paystack_public_key: 'pk_test_24673c9637a1bf06e5fb6eb989012747183eb2ae',
   remitta_mode: 'test',
  remitta_api_key: 'QzAwMDAyNTE5MzV8NTcwODYyNDl8NTk0ZGFkZTE2OWM3N2UyZTMyMzlhYWJiZjM4NjdiZThhYWQyMTUxMWVlMjNiMjdjYmFmODVlNTZlMDFlNTA5ZGZjNDVhOTliM2Q2OGNhZmE0YmI3YzllODBhMTdmNmIxMDc4ZWRlMWM5MDc1OTc4ZGYxMDQ5ZjIxYWU2Mjc0YjQ=',
  interswitch_mechant_code: 'VNA',
  interswitch_pay_item: 'Default_Payable_MX128354',
  interswitch_mode: 'LIVE',
  currentCourtInfoEmail: 'judiciary.dl.gov.ng',
  // EXTRAS
  showHelpLine: true,
  helpLine: "09137146567, 07073735972",
  showJudiciarySuffix: false,
  showState: false,
  showDivision: true,
  showSupCourtTitle: false,
  supCourtTitle:'The Judiciary,',
  supCourtTitleColor:'red',
  showAffidavitFee: true
};
